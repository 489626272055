var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","flat":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.deviceList.loading,"headers":_vm.deviceList.headers,"items":_vm.deviceList.filteredItems,"hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('thead',[_c('tr',_vm._l((props.headers),function(item,idx){return _c('th',{key:("header_" + idx),staticClass:"text-center",style:({
                minWidth: ((item.minWidth) + "px"),
                maxWidth: ((item.maxWidth) + "px"),
                width: ((item.width) + "px"),
                verticalAlign: 'middle'
                })},[_c('div',{staticClass:"d-flex align-center"},[(item.filterable)?_c('div',{staticClass:"pt-4 pb-2 header-col"},[(item.filterable instanceof Array)?_c('v-select',{attrs:{"label":props.headers[idx].text,"items":item.filterable,"multiple":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.onFilter(item.value, props.headers[idx].filterValue)}},model:{value:(props.headers[idx].filterValue),callback:function ($$v) {_vm.$set(props.headers[idx], "filterValue", $$v)},expression:"props.headers[idx].filterValue"}},[_vm._v(" SELECT ")]):_c('v-text-field',{attrs:{"label":props.headers[idx].text,"outlined":"","hide-details":"","dense":""},on:{"input":function($event){return _vm.onFilter(item.value, props.headers[idx].filterValue)}},model:{value:(props.headers[idx].filterValue),callback:function ($$v) {_vm.$set(props.headers[idx], "filterValue", $$v)},expression:"props.headers[idx].filterValue"}})],1):_c('div',{staticClass:"v-label pb-4 pt-4"},[_c('span',[_vm._v(_vm._s(props.headers[idx].text))])]),(props.headers[idx].sortable)?_c('div',[_c('v-btn',{staticClass:"sort-btn",class:{
                      'sort-active': (props.options.sortBy[0] === item.value),
                      'sort-desc': ((props.options.sortBy[0] === item.value)
                        && props.options.sortDesc[0]),
                      },attrs:{"small":"","icon":""},on:{"click":function($event){return on.sort(item.value)}}},[_c('v-icon',[_vm._v(" mdi-chevron-up ")])],1)],1):_vm._e()])])}),0)])]}},{key:"item.router_usage",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toUsageString(item.router_usage))+" ")]}},{key:"item.voltage",fn:function(ref){
                      var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s((item.voltage != null) ? (item.voltage / 1000) : '-')+" ")])]}},{key:"item.uptime",fn:function(ref){
                      var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.toUptimeText(item.uptime))+" ")])]}},{key:"item.timestamp",fn:function(ref){
                      var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.toLocalDate(item.timestamp))+" ")])]}},{key:"item.stat",fn:function(ref){
                      var item = ref.item;
return [_c('div',[_c('v-icon',[_vm._v(" "+_vm._s((item.stat === 'connected') ? 'mdi-signal' : 'mdi-signal-off')+" ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }